import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';

import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import App from 'App';
import { ProtectedRoute } from './ProtectedRoute';
import DashboardPage from './pages/dashboard';
import CallsListPage from './pages/calls/list';
import CallsCreatePage from './pages/calls/create';
import HospitalListPage from './pages/hospital';
import LoginPage from './pages/login';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            )
          },
          {
            path: '/calls',
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <CallsListPage />
                  </ProtectedRoute>
                )
              },
              {
                path: `/calls/create`,
                element: (
                  <ProtectedRoute>
                    <CallsCreatePage />
                  </ProtectedRoute>
                )
              }
            ]
          },
          {
            path: '/hospitals',
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute>
                    <HospitalListPage />
                  </ProtectedRoute>
                )
              }
            ]
          }
        ]
      },
      {
        path: '/',
        children: [
          {
            path: 'login',
            element: <LoginPage />
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);
